import React from 'react'

export default function Team() {
  return (
    <div>
      <h1>Team</h1>
      <p>This is our team, we're from all over the world!.</p>
    </div>
  )
}
